<template>
    <div>
        <the-app-bar/>
        <Supercategories/>
        <Footer/>
    </div>
</template>

<script>
import Footer from '../../../components/footer.vue'
import Supercategories from '@/components/HealthHub/Supercategories.vue'
import TheAppBar from '../../../components/NavBar/TheAppBar.vue'
    export default {
  components: { TheAppBar, Footer, Supercategories,  },
        
    }
</script>

<style lang="scss" scoped>

</style>