<template>
  <div class="font-montserrat top">
    <div class="hero-banner">
      <img
        src="../../assets/images/HealthHub/healthhubbanner.webp"
        alt="Hero Banner Image"
        class="hero-image"
      />
    </div>

    <div v-if="loading" class="row py-5 justify-content-center">
      <fade-loader :loading="loading" :color="color"></fade-loader>
    </div>
    <div v-else class="container">
      <div class="row lg:pl-24 py-5 ">
        <div class="container">
          <div class="col-md-12 text-right">
            <a
              v-if="islogin"
              href="/view-healthhub-purchased-packs"
              class="btn oonpay-bg text-white btn-sm"
              >View Purchased Pack</a
            >
          </div>
        </div>
        <div class="col-md-12 mb-4"><h4>Select a package to purchase</h4></div>

        <div class="col-md-3" v-for="item in supercategories" :key="item.id">
          <router-link :to="`/categories/${item.id}`" class="card">
            
            <img
             v-if="item.imageUrl"
              v-lazy="
                `${healthhubbaseUrl}/oonpayhealthhubsetup/${item.imageUrl}?cache=${uniqueParam}`
              "
              class="card-img-top fixed-size-image"
              alt="Product Image"
              @load="imageLoaded"
              
            />
             <div v-if="isLoading" class="spinner">
              <img src="@/assets/Spinner.gif" alt="Loading..." />
            </div>
            <div class="card-body text-center" v-if="!loading">
              <h5 class="card-title">{{ item.fullName.length > 20 ? item.fullName.slice(0, 20) + '...' : item.fullName }}</h5>

              <router-link
                :to="`/categories/${item.id}`"
                class="btn btn-primary btn-block mt-5"
              >
                View Pack
              </router-link>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { ImagebaseUrl } from '../../config';
export default {
  name: "Supercategories",
  data() {
    return {
      healthhubbaseUrl:ImagebaseUrl,
      loading: true,
      color: "#0b4369",
      islogin: localStorage.getItem("login_status"),
      uniqueParam: Date.now(),
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("healthhubclient", ["supercategories", "subcategories"])
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("healthhubclient/getHealthhubSupercategory")
      .then((res) => {
        if (res.status == 200) {
          this.loading = false;
        }
      });
  },
  components: {
    FadeLoader
  },
  methods: {
    imageLoaded() {
      // This method is triggered when the image is successfully loaded
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.fixed-size-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.hero-banner {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: auto;
  display: block;
}

.bg-color {
  background: #f7f4fd;
}

.oonpay-bg {
  background: #0c4369;
}

@media screen and (max-width: 720px) {
  .hero-banner {
    position: relative;
    margin-top: 78px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .hero-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
